import React from 'react';
import HeaderInner from '../component/Layout/HeaderInner';
import AOS from 'aos';

import "../assets/scss/terms.scss"
import 'aos/dist/aos.css';


export const Terms = () => {
    React.useEffect(() => AOS.init(), []);
    return (
        <>
            <HeaderInner />


            <div className="container content terms-page">
                <br />
                <br />
                <br />
                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '16pt' }}>
                            <span style={{ fontFamily: 'Calibr, sans-serif' }}>
                                <strong style={{ fontWeight: 'bold' }}>
                                    <span style={{ fontSize: '18.0pt' }}>
                                        <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                            WSB.sh General Terms of Use
                                        </span>
                                    </span>
                                </strong>
                            </span>
                        </span>
                    </span>
                </p>
                <br />
                <p><span style={{ color: '#ffffff' }}>
                    <span style={{ fontSize: '11pt' }}>
                        <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                            <strong style={{ fontWeight: 'bold' }}>
                                <span style={{ fontSize: '11pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        LAST UPDATED: 26.11.2022
                                    </span>
                                </span>
                            </strong>
                        </span>
                    </span>
                </span>
                </p>
                <br />


                <p>
                    <span style={{ color: 'rgb(255, 255, 255)' }} >
                        <span style={{ fontSize: "14pt" }}>
                            <span style={{ fontFamily: 'Calibr, sans-serif' }}>
                                <strong style={{ fontWeight: 'bold' }}>
                                    <span style={{ fontSize: '10pt' }}>
                                        <span style={{ fontFamily: 'EB Garamond, serif' }}>PLEASE READ THESE GENERAL TERMS & CONDITIONS CAREFULLY AS THEY CONTAIN IMPORTANT INFORMATION AND AFFECT YOUR LEGAL RIGHTS. THEY INCLUDE A CLASS ACTION WAIVER.
                                        </span></span></strong></span></span></span>
                </p>

                <br />



                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        These General Terms of Use (the "<b>Terms</b>") apply to your access to, and use of, our (as defined below) Infrastructure (as defined below) , including the website
                                        [<a href="https://wsb.sh" target="blank" >wsb.sh</a>, <a href="https://app.wsb.sh" target="blank" >app.wsb.sh</a>] (the "<b>Website</b>"), Platform (as defined below) and Token (as defined below). These Terms do not alter the terms of any other agreement you may have with us for products, services or otherwise (the "<b>Agreement</b>"). To the extent there is a conflict between any Agreement and these Terms, the terms of these Terms shall govern. The Terms are entered into between you and us. You must be 18 (eighteen) years or older to access and use the Infrastructure. If you are under 18 (eighteen) years of age, you are not permitted to access and use the Infrastructure. If you are accepting the Terms on behalf of a company or other legal entity (the "<b>Entity</b>"), you represent that you have the legal authority to accept the Terms on the Entity's behalf, in which case "you" will mean that Entity. If you do not have such authority, or if you do not accept the Terms, then we are unwilling to make the Infrastructure available to you.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        By accessing or using, or both, of the Infrastructure, you agree that you have read, understood, and accepted all the terms and conditions stipulated in these Terms and you agree to be legally bound by these Terms. If you do not agree to these Terms, do not use the Infrastructure. By agreeing to these Terms you warrant and represent to us that you are not (i) located in; or (ii) under the control of a legal entity, which is established under the laws of, or resident of, or of an individual, which is a national of, or resident of; or (iii) legal entity, which is established under the laws of, or resident of; or (iv) individual, which is a national of, or resident of, the United States of America, China, Bolivia, Columbia, North Korea, Indonesia, Turkey, or any other country or jurisdiction, in which the access or use, or both, of the Infrastructure in prohibit, or may potentially be prohibited under applicable law.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>
                <br />

                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        In addition, when accessing or using, or both, of the Infrastructure, you shall be subject to any posted guidelines or rules applicable to the Infrastructure, which may be posted and modified from time to time by us or any of our Affiliate (as defined below). All such guidelines or rules are hereby incorporated by reference into these Terms.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>
                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        We may make changes to these Terms from time to time. The changes will be effective when published. Please review the Terms on a regular basis. You understand and agree that your express acceptance of the Terms or your access or use, or both, of the Infrastructure, after the date of publication shall constitute your agreement to the updated Terms. You can determine when these Terms were last revised by referring to the "LAST UPDATED" legend at the top of the then-current version of the Terms on the Website or any other website as may be determined by us from time to time.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>
                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        The Terms or any part thereof may be translated into other languages for your convenience. The English language version of each of these documents is the version that always prevails and in the event of any conflict between the English language version and a translated version, the English language version shall prevail.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>
                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        If you are accessing or using, or both, of the Infrastructure with third party products, hardware, software applications, programs or devices (the "<b>Third Party Technology</b>"), you agree and acknowledge that: (i) you may be required to enter into a separate licence agreement with the relevant third party owner or licensor for the use of such Third Party Technology, (ii) the Infrastructure may not be accessible through the Third Party Technology, and (iii) we cannot guarantee that the Infrastructure shall always be available on or in connection with such Third Party Technology.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>
                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        Certain features of the Infrastructure may be offered while still in the "beta" version (the "<b>Beta Versions</b>"). We shall use our reasonable commercial efforts to identify the Beta Versions by marking them within the Infrastructure. By accepting the Terms, you understand and acknowledge that the Beta Versions are being provided as a "BETA" version and made available on an "AS IS" and "AS AVAILABLE" basis. The Beta Versions may contain bugs, errors, and other problems. YOU ASSUME ALL RISKS AND ALL COSTS ASSOCIATED WITH YOUR USE OF THE BETA VERSIONS, INCLUDING ANY INTERNET ACCESS FEES, BACK-UP EXPENSES, COSTS INCURRED FOR THE USE OF YOUR DEVICE AND PERIPHERALS, AND ANY DAMAGE TO ANY EQUIPMENT, SOFTWARE, INFORMATION OR DATA. In addition, we shall not be obliged to provide any maintenance, technical, or other support for the Beta Versions.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>
                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        The Infrastructure presents the current status and future plans for the Project (as defined below). The sole purpose of the Infrastructure is to provide information and is not to provide a precise description on future plans. Unless explicitly stated otherwise, the Infrastructure is still under development and we or any of our Affiliate, or both, does not provide a statement of quality assurance or affidavit for the successful development or execution of the Project.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>
                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        Certain components of the Infrastructure may be published under various licenses by us, including open-source licenses (the "<b>Applicable Licenses</b>"). To the extent there is a conflict between any Applicable License and these Terms, the terms of the Applicable License shall govern.
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>
                <br />



                <p><span style={{ color: '#ffffff' }}>
                    <span style={{ fontSize: '11pt' }}>
                        <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                            <strong style={{ fontWeight: 'bold' }}>
                                <span style={{ fontSize: '18.0pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        1. DEFINITIONS AND INTERPRETATION

                                    </span>
                                </span>
                            </strong>
                        </span>
                    </span>
                </span>
                </p>
                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        1.1. In addition to the terms defined elsewhere in these Terms, for all purposes of these Terms, the following terms have the meanings set forth in this Section 1.1:

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (1) "<b>Affiliate</b>" means, in relation to any person at a given time, any other person that, directly or indirectly, or both, controls, is controlled by or is under common control, with such person. For the purposes of these Terms, "<b>control</b>" (including, with correlative meanings, the terms "<b>controlled by</b>" and "<b>under common control with</b>"), as used with respect to any person, means the possession, directly or indirectly, or both, of the power to direct or cause the direction, or both, of the management and policies of such person, whether through the ownership of voting shares, by contract, or otherwise;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (2) "<b>Content</b>" the information, materials, services, and other content available on or through the Infrastructure from time to time;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (3) "<b>Governmental Authority</b>" means any nation or government, any state or other political subdivision thereof, any entity exercising legislative, executive, judicial or administrative functions of or pertaining to government, including without limitation any government authority, agency, department, board, commission or instrumentality and any court, tribunal or arbitrator(s) of competent jurisdiction and any self-regulatory organization. For the avoidance of doubt, Governmental Authority may include private bodies exercising quasi-governmental, regulatory or judicial-like functions to the extent they relate to any one or more of the following: you, us, Infrastructure or Project;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (4) "<b>Infrastructure</b>" means any one or more of the following: Website, Token, Platform and any other correlative software, materials, documents and IP which we or any of our Affiliate, or both, make available in connection with your access or use, or both, of any one or more of the following: the Website, Token or Platform.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (5) "<b>IP Rights</b>" means all vested, contingent and future intellectual property rights, including worldwide statutory and common law rights, relating to, or owned by the relevant person anywhere in the world in IP, and all its variations, modifications or enhancements together with any application or right to apply for registration, renewal, extension or protection of those rights;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (6) "<b>IP</b>" means: any or all of the following anywhere in the world: (i) all patents; (ii) all inventions (whether patentable or not), ideas, processes, invention disclosures, improvements, trade secrets, proprietary information, know-how, technology, improvements, discoveries, technical data, customer lists, proprietary processes and formulae, all source and object code, algorithms, architectures, structures, display screens, layouts, development tools and all documentation and media constituting, describing or relating to the above, including manuals, memoranda and records; (iii) all copyrights, copyrightable material including derivative works, revisions, transformations and adaptations, material that is subject to non-copyright disclosure protections, and all other works of authorship and designs (whether or not copyrightable); (iv) all Trademarks; (v) domain names; (vi) web sites and related content, and (vii) all manuals, documentation and materials relating to the above;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (7) "<b>We</b>" (""<b>us</b>" or "<b>our</b>") means owner of the IP Rights to the Infrastructure;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />

                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (8) "<b>Platform</b>" means a decentralized substrate-based platform with the in-built Token created to democratise access to prediction markets in a fair and transparent way. Users of the Platform are able, independently and without any permission, to use their prediction abilities and participate in prediction pools by staking the Tokens on the Platform. Completely trustless and decentralized, the Platform does not require any third party to have custody over assets, as protocols transparently lock in tokens in individual prediction pools. The detailed description of the Platform provided for at: [<a href="https://app.wsb.sh" target="blank" >app.wsb.sh</a>];

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />

                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (9) "<b>Project</b>" means development of the Platform;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />

                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (10) "<b>Token</b>" means a native utility token of the Platform, WSBT, that is programmed using a smart contract system;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (11) "<b>Trademarks</b>" means: (i) the trademarks, trade names and service marks used by the relevant person, whether registered or unregistered; (ii) the respective stylistic marks and distinctive logotypes for such trademarks, trade names and service marks, and (iii) such other marks and logotypes as the relevant person may designate from time to time in writing; and

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (12) "<b>User</b>" ("<b>you</b>" or "<b>your</b>") means you as the user of the Infrastructure.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />


                <p><span style={{ color: '#ffffff' }}>
                    <span style={{ fontSize: '11pt' }}>
                        <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                            <strong style={{ fontWeight: 'bold' }}>
                                <span style={{ fontSize: '18.0pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        2. USE OF THE INFRASTRUCTURE

                                    </span>
                                </span>
                            </strong>
                        </span>
                    </span>
                </span>
                </p>
                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        2.1. In consideration for your acceptance of the Terms, we grant you the limited, revocable, personal, non-transferable, non-sublicensable and non-exclusive right to access and use the Infrastructure.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        2.2. You shall not access or use, or both, the Infrastructure in any manner that may impair, overburden, damage, disable or otherwise compromise the Infrastructure.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        2.3. When you access or use, or both, the Infrastructure, you agree and undertake to comply with the following provisions:

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (1) during the access to, or use of, or both, the Infrastructure, all activities you carry out should comply with the requirements of applicable laws and regulations, the Terms, our various guidelines and Applicable Licenses;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (2) your use of the Infrastructure should not violate public interests, public morals, or the legitimate interests of others; and

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (3) unless otherwise provided for in the Applicable Licenses, without prior written consent from us, you shall not: (i) copy, modify, reproduce, translate, localize, port or otherwise create derivatives of any part of the Infrastructure; (ii) reverse engineer, disassemble, decompile or otherwise attempt to discover the source code or structure, sequence and organization of all or any part of the Infrastructure (except that this restriction shall not apply to the limited extent restrictions on reverse engineering are prohibited by applicable local, state, provincial, national or other law, rule or regulation); (iii) rent, lease, resell, distribute, use in any unauthorized or unintended manner or otherwise exploit the Infrastructure for purposes not contemplated by the Terms; (iv) remove or alter any proprietary notices, Trademarks or labels on or in the Infrastructure; and (v) engage in any activity that interferes with or disrupts the Infrastructure.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        2.4. Your access to, or use of, the Infrastructure may be interrupted from time to time for any of several reasons, including the malfunction of equipment, periodic updating, maintenance, or repair of the Infrastructure or other actions that we, in our sole discretion, may elect to take.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        2.5. Because we have a growing number of services and enhancing the Infrastructure to better meet the needs of our Users' community, we sometimes need to provide additional terms for specific services. Those additional terms and conditions, which are available with the relevant service, then become part of your agreement with us if you use those services. In the event of a conflict between the Terms and any additional applicable terms we may provide for a specific service, such additional terms shall control for that specific service. We also may from time to time modify, suspend or discontinue, temporarily or permanently, any part of the Infrastructure for any reason.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />

                <p><span style={{ color: '#ffffff' }}>
                    <span style={{ fontSize: '11pt' }}>
                        <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                            <strong style={{ fontWeight: 'bold' }}>
                                <span style={{ fontSize: '18.0pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        3. CONTENT AND IP RIGHTS

                                    </span>
                                </span>
                            </strong>
                        </span>
                    </span>
                </span>
                </p>
                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        3.1. The Infrastructure may contain materials, including information, Trademarks, data, text, editorial content, design elements, look and feel, formatting, graphics, images, photographs, videos, music, sounds and other content, which owned, operated, licensed, or controlled by us and which is protected by copyright, trademark, trade secret, or other proprietary rights (collectively, "Our Content"). We or our relevant suppliers, or licensors, retain all rights in such Our Content. We grant you a limited, revocable, personal, non-transferable, non-sublicensable and non-exclusive right to view Our Content solely for your internal access to, and use of the Infrastructure.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        3.2. Our Content is for informational purposes only, you should not construe any such information or other material as legal, tax, investment, financial, or other advice.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />

                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        3.3. All Our Content is information of a general nature and does not address the circumstances of any particular individual or entity. Nothing in Our Content constitutes a comprehensive or complete statement of the matters discussed or the law relating thereto. You alone assume the sole responsibility of evaluating the merits and risks associated with the access to, or use of, or both, of the Infrastructure before making any decisions based on the information contained in Our Content.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />

                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        3.4. You retain any and all IP Rights you already hold under applicable law in materials, including information, data, text, editorial content, design elements, look and feel, formatting, graphics, images, photographs, videos, music, sounds and other content you upload, publish, and submit to or through the Infrastructure (the "<b>User Content</b>"), subject to the rights, licenses, and other terms of the Terms, including any underlying our rights or rights of other users in the relevant content that you may use or modify.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        3.5. In connection with the User Content you affirm, represent, and warrant that you own or have all necessary IP Rights, licenses, consents, and permissions to use and authorize us and users to use the User Content in the manner contemplated by the Terms.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        3.6. Because the law may or may not recognize certain IP Rights in any particular User Content, you should consult a lawyer if you want legal advice regarding your legal rights in a specific situation. You acknowledge and agree that you are responsible for knowing, protecting, and enforcing any IP Rights you hold, and that we cannot do so on your behalf.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        3.7. Except as prohibited by any applicable law, you hereby waive, and you agree to waive, any moral rights (including attribution and integrity) that you may have in any User Content, even if it is altered or changed in a manner not agreeable to you. To the extent not waivable, you irrevocably agree not to exercise such rights (if any) in a manner that interferes with any exercise of the granted rights. You understand that you will not receive any fees, sums, consideration or remuneration for any of the rights granted in this section.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        3.8. You hereby grant to us, and you agree to grant to us, the non-exclusive, unrestricted, unconditional, unlimited, worldwide, irrevocable, perpetual, and royalty-free right and license to use, copy, record, distribute, reproduce, disclose, modify, display, publicly perform, transmit, publish, broadcast, translate, make derivative works of, and sell, re-sell or sublicense (through multiple levels), and otherwise exploit in any manner whatsoever, all or any portion of your User Content (and derivative works thereof), for any purpose whatsoever in all formats, on or through any media, software, formula, or medium now known or hereafter developed, and with any technology or devices now known or hereafter developed, and to advertise, market, and promote the same. You agree that the license includes the right to copy, analyse and use any User Content as we may deem necessary or desirable for purposes of debugging, testing, or providing support or development services in connection with the Infrastructure and future improvements to the Infrastructure.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        3.9. We shall not be obliged to monitor or enforce your IP Rights to your User Content, but you grant us the right to protect and enforce our rights to your User Content, including by bringing and controlling actions in your name and on your behalf (at our cost and expense, to which you hereby consent and irrevocably appoint us as your attorney-in-fact, with the power of substitution and delegation, which appointment is coupled with an interest).

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        3.10. You acknowledge and agree that Our Content and Infrastructure fallen into our IP or its relevant suppliers or licensors, and IP Rights to it and to all related materials to it is owned by us or its relevant suppliers or licensors. All uses of our IP shall inure to our benefit.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        3.11. You shall use our IP: (i) only in strict accordance with specifications and directions supplied by or on our behalf from time to time; (ii) only in connection with access to, or use of, or both, the Infrastructure and (iii) only in the form and style approved by us.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        3.12. You shall not include all or any portion of our IP in your IP or in the IP of any other person, or both.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        3.13. You shall not use our IP in a manner likely to cause confusion with, dilute or damage our goodwill, reputation or image.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        3.14. You shall not register, attempt to register or lay common law claim to any Our Content, our IP, or any IP, confusingly similar to Our Content or our IP, or both.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        3.15. No transfer, grant or license of IP Rights to our IP or Our Content, or both, is made or is to be implied by the Terms except as may be expressly stated otherwise herein.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />



                <p><span style={{ color: '#ffffff' }}>
                    <span style={{ fontSize: '11pt' }}>
                        <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                            <strong style={{ fontWeight: 'bold' }}>
                                <span style={{ fontSize: '18.0pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        4. REPRESENTATIONS AND WARRANTIES

                                    </span>
                                </span>
                            </strong>
                        </span>
                    </span>
                </span>
                </p>
                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        4.1. You hereby represent and warrant to us that:

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (1) you understand and accept that the smart contract system concept, the underlying software application and software platform is still in an early development stage and unproven, why there is no warranty that the process for creating the Infrastructure, or both, will be uninterrupted or error-free and why there is an inherent risk that the software could contain weaknesses, vulnerabilities or bugs causing, inter alia, the complete loss of the Token;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (2) you understand and accept that the blockchain technology allows new forms of interaction and that it is possible that certain jurisdictions will apply existing regulations on, or introduce new regulations addressing, blockchain technology based applications, which may be contrary to the current setup of the smart contract system and which may, inter alia, result in substantial modifications of the smart contract system or Infrastructure, or both, including its termination and the loss of the Token for you;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (3) you understand and accept that the creation of the Token and the development of the Infrastructure and Project may be abandoned for a number of reasons, including lack of interest from the public, lack of funding, lack of commercial success or prospects (e.g. caused by competing projects). You therefore understand that there is no assurance that, even if the Project is partially or fully developed and launched, you will receive any benefits through the Token held by you;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (4) you understand and accept that the Infrastructure may give rise to other, alternative projects, promoted by unaffiliated to us third parties, under which the Token will have no intrinsic value;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (5) the Token can only be accessed by using a virtual assets wallet with a combination of your account information (address), private key and password. The private key is encrypted with a password. You understand and accept that if your private key file or password respectively got lost or stolen, the obtained Token associated with your account (address) or password will be unrecoverable and will be permanently lost;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (6) you understand and accept that the smart contract system concept, the underlying software application and software platform may be exposed to attacks by hackers or other persons that could result in theft or loss of the Token, impacting the ability to develop the Infrastructure;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (7) you understand and accept that, as with other virtual assets, the blockchain used for the smart contract system is susceptible to mining attacks, including double-spend attacks, majority mining power attacks, "selfish-mining" attacks, and race condition attacks. Any successful attacks present a risk to the smart contract system, expected proper execution and sequencing of Token transactions, and expected proper execution and sequencing of contract computations;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (8) you understand and accept that the wallet or wallet service provider used by you have to be technically compatible with the Token. The failure to assure this may have the result that you will not gain access to the Token;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (9) you have a deep understanding of the functionality, usage, storage, transmission mechanisms and intricacies associated with virtual assets, like Token, BTC and ETH, and blockchain-based software systems;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (10) you have carefully reviewed these Terms and the code of the relevant smart contract system and fully understand and accept the functions implemented therein;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (11) you understand and accept that the Token is cryptographic utility token. The purpose of the Token is to enable usage and facilitate the participation and interaction with the Infrastructure and Platform, support their development and operation and use it as service fee in related to the Infrastructure activities;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (12) you understand and accept that ownership of Token carries no rights, whether express or implied, other than a limited right (license) to use the relevant parts of the Infrastructure as well as the conversion function of the smart contract system, both as specified in these Terms, if and to the extent the Platform has been successfully completed and launched;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (13) you understand and accept that by receiving of Token no form of partnership, joint venture or any similar relationship between you and us, or any of its Affiliate, or both, is created;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (14) you understand and accept that except as otherwise expressly set forth herein, the receipt of Token: (i) does not provide you with rights of any form with respect to any one or more of the following: us, our Affiliates, or their revenues or assets, including, without limitation, any voting, distribution, redemption, liquidation, proprietary (including all forms of their IP Rights) or other financial or legal rights; (ii) is not a loan to us; and (iii) does not provide you with any ownership, equity, or other interest in us or any of our Affiliate, or both;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (15) you understand and accept that that we retains all IP Rights in all of our IP contained in Infrastructure, or any other IP, arising out or in connection with the Infrastructure;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (16) you understand and accept that the Token may only be utilized on the Infrastructure, are non-refundable and cannot be exchanged for cash or any payment obligation by us or any of our Affiliate, or both;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (17) you understand and accept that the Token: (i) does not represent or confer on you any ownership right, shareholding, participation, right, title, or interest of any form with respect to us, or any of its Affiliate, or both, or any other company, enterprise or undertaking, or any of their revenues or assets, including without limitation any right to receive future revenue, dividends, shares, ownership right or stake, share or security, any voting, distribution, redemption, liquidation, proprietary (including all forms of IP), or other financial or legal rights or equivalent rights, or IP Rights or any other form of participation in or relating to the Infrastructure; (ii) is not intended to be a representation of currency or money (whether fiat or virtual or any form of electronic money), security, commodity, or any other kind of financial instrument or investment; (iii) is not intended to represent any rights under a contract for differences or under any other contract the purpose or pretended purpose of which is to secure a profit or avoid a loss; (iv) is not a loan and is not intended to represent a debt owed by any person, and there shall be no expectation of profit or interest income arising in connection therewith; (v) is not any form of financial derivative; (vi) is not any form of commercial paper or negotiable instrument, and (vii) is not any commodity or asset that any person is obliged to redeem or purchase, or both;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (18) you are not using Token for the purpose of speculative investment or any illegal purposes;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (19) you understand that we, or any of our Affiliate, or both, have no obligation to buy back any Token from you, are not indebted to you, are not otherwise required to repay you in money or in kind, provide any services or to deliver any goods, products or IP Rights to you as the result of these Terms;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (20) you understand that the Infrastructure and Project carries significant financial, regulatory and reputational risks. No warranty whatsoever on any one or more of the following: the Infrastructure, smart contract system or the success of the Project, expressed or implied, to the extent permitted by law, and that the smart contract system is used and Token is obtained at your sole risk on an "AS IS" and "UNDER DEVELOPMENT" basis and without, to the extent permitted by law, any warranties of any kind, including, but not limited to, warranties of title or implied warranties, merchantability or fitness for a particular purpose;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (21) you understand with regard to the Token no market liquidity may be guaranteed and that the value of the Token over time may experience extreme volatility or depreciate in full;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (22) you understand that you bear the sole responsibility to determine the action or transaction related to the Token has tax implications for you; by exchanging, holding or using Token, and to the extent permitted by law, you agrees not to hold us or any of our Affiliate, or both, liable for any tax liability associated with or arising from the exchange, ownership or use of the Token or any other action or transaction related to the Platform;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (23) you have all requisite power and authority to carry out and perform all your obligations under these Terms. All action on your part required for the lawful performance of all your obligations under these Terms have been or will be effectively taken. These Terms constitutes a legal, valid and binding obligation of you enforceable against you in accordance with its terms, except that such enforceability may be limited by applicable bankruptcy, insolvency, reorganization, moratorium and similar laws of general application relating to or affecting creditors' rights generally and by equitable principles (regardless of whether enforcement is sought in a proceeding in equity or at law);

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (24) the execution, delivery and performance of these Terms will not result in: (i) any violation of, be in conflict with or constitute a material default under, with or without the passage of time or the giving of notice of: (a) any agreement, obligation, duty or commitment to which you are a party or by which you are bound; (b) any laws, statutes, ordinances, rules, regulations, judgments, injunctions, administrative interpretations, orders and decrees of any Governmental Authority, including amendments thereto (collectively, the "Laws"), or (ii) the creation of any lien, charge or encumbrance upon any your assets;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (25) the performance under these Terms require no approval or other action from any Governmental Authority or person or entity other than our approval or other action, except for such consents, approvals, authorizations, orders, filings, registrations or qualifications as have already been obtained or made and are still in full force and effect;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (26) you are not relying on our or any of our Affiliates, owners, directors, officers, counsel, employees, agents or representatives for legal, investment or tax advice. You represent that to the extent that you have any questions with respect to these Terms, you have sought professional advice;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (27) neither you, nor any person for whom you are acting as agent or nominee in connection with Infrastructure, has been or is: (i) the subject of sanctions administered or enforced by the United States (including without limitation the U.S. Department of the Treasury's Office of Foreign Asset Control), the United Kingdom, the European Union or any other Governmental Authority (collectively, the "Sanctions"), (ii) resident in a country or territory that is the subject of country-wide or territory-wide Sanctions, or (iii) otherwise a party with which we are prohibited from dealing with under applicable Laws;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (28) to the extent required by applicable laws, you have complied and will continue to comply with all anti-money laundering and counter-terrorism financing requirements;

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (29) the assets, including any fiat or virtual assets, or both, you use to perform your obligations hereunder are not derived from or related to any unlawful activities, including but not limited to money laundering or terrorist financing, and you will not use, or permit the use of, the Token to finance, engage in or otherwise support any unlawful activities; and

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (30) you hereby understand and accept that you access to, and use of, the Infrastructure, stake the Tokens on the Platform and participate in any other way in prediction pools on the Platform at your sole and absolute discretion. We have no access to your staked on the Platform Tokens and to the relevant prediction pools, and, therefore, we shall not be liable for any direct or indirect, special, incidental, punitive, consequential, or exemplary damages of any kind (including, but not limited to, where related to loss of revenue, income or profits, loss of use or data, or damages for business interruption) arising out of or in any way related to your accessing to, or using of, the Infrastructure, staking Tokens on the Platform by yourself and your participation in any other way in prediction pools.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        (31) you are aware that it may be unable to trade or otherwise dispose of ownership of the Token. You acknowledge and agree that it may be required to bear the financial risks of the Token for an indefinite period of time.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />


                <p><span style={{ color: '#ffffff' }}>
                    <span style={{ fontSize: '11pt' }}>
                        <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                            <strong style={{ fontWeight: 'bold' }}>
                                <span style={{ fontSize: '18.0pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        5. LIMITATION OF LIABILITY; NO WARRANTIES

                                    </span>
                                </span>
                            </strong>
                        </span>
                    </span>
                </span>
                </p>
                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        5.1. Except as expressly provided by these Terms and applicable Laws, we shall not be responsible or liable for any losses resulting directly or indirectly from: (i) any act or omission of you or any of your Affiliate, or both, or their agent, or any error, negligence, or misconduct of you, or any of your Affiliate, or both; (ii) failure of transmission or communication facilities; (iii) any other cause or causes beyond our control, including, without limitation, for reasons such as acts of God, fire, flood, strikes, work stoppages, acts of terrorism, governmental or regulatory action, delays of suppliers or subcontractors, war or civil disturbance, self-regulatory organization actions, telecommunication line or computer hardware failures and any other telecommunication failures; (iv) our reliance on any instructions, notices, or communications that it believes to be from an individual authorized to act on behalf of you, and you hereby waives any and all defences that any such individual was not authorized to act on behalf of you; (v) government restrictions; exchange, regulatory, or market rulings; suspension of trading; military operations; terrorist activity; strikes, or any other condition beyond our control, including without limitation extreme market volatility or trading volume; or (vii) any action taken by us to comply with applicable laws or these Terms.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        5.2. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW (I) IN NO EVENT WILL WE BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, CONSEQUENTIAL, OR EXEMPLARY DAMAGES OF ANY KIND (INCLUDING, BUT NOT LIMITED TO, WHERE RELATED TO LOSS OF REVENUE, INCOME OR PROFITS, LOSS OF USE OR DATA, OR DAMAGES FOR BUSINESS INTERRUPTION) ARISING OUT OF OR IN ANY WAY RELATED TO THE INFRASTRUCTURE OR PROJECT, OR BOTH, OR OTHERWISE RELATED TO THESE TERMS, REGARDLESS OF THE FORM OF ACTION, WHETHER BASED IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO, SIMPLE NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED), OR ANY OTHER LEGAL OR EQUITABLE THEORY (EVEN IF THE PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE); (II) IN NO EVENT WILL THE AGGREGATE OUR LIABILITY, WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED), OR OTHER THEORY, ARISING OUT OF OR RELATING TO THE INFRASTRUCTURE OR PROJECT, OR BOTH, OR OTHERWISE RELATED TO THESE TERMS, OR THE USE OF OR INABILITY TO USE THE TOKEN, EXCEED THE USD 100 (ONE HUNDRED). THE LIMITATIONS SET FORTH IN THIS SECTION WILL NOT LIMIT OR EXCLUDE LIABILITY WHICH SHALL NOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p><span style={{ color: '#ffffff' }}>
                    <span style={{ fontSize: '11pt' }}>
                        <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                            <strong style={{ fontWeight: 'bold' }}>
                                <span style={{ fontSize: '18.0pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        6. INDEMNIFICATION

                                    </span>
                                </span>
                            </strong>
                        </span>
                    </span>
                </span>
                </p>
                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        You hereby agree to indemnify any one or more of the following: us, any of our Affiliate, and its and their respective owners, directors, officers, employees, representatives and advisors, and to hold each of them harmless, from and against any loss, damage, liability, cost or expense, including reasonable attorneys' fees and costs of investigation, to which they may be put or which they may reasonably incur or sustain due to or arising out of; (i) any inaccuracy in or breach of any your representation or warranty or your Affiliate or agents, whether contained in these Terms or any other document provided by you to us in connection with these Terms; (ii) any nonfulfillment or breach of any covenant, agreement, or other provision by you or your Affiliate or agents, whether contained in these Terms or any other document provided by you to us in connection with these Terms, or (iii) the receiving by you of Token in violation of the applicable law or these Terms. Notwithstanding any provision of these Terms, you do not waive any right granted to you under any applicable Law. All indemnification provisions shall survive the termination of these Terms.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p><span style={{ color: '#ffffff' }}>
                    <span style={{ fontSize: '11pt' }}>
                        <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                            <strong style={{ fontWeight: 'bold' }}>
                                <span style={{ fontSize: '18.0pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        7. NOTICES

                                    </span>
                                </span>
                            </strong>
                        </span>
                    </span>
                </span>
                </p>
                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        7.1. Any notice or other document given to us under these Terms (the "<b>Notice</b>") shall be: (i) in writing in the English language (or be accompanied by an accurate translation into English); (ii) signed by or on behalf of the person giving it, and (iii) sent by email.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        7.2. The Notice shall be delivered to us at the following email address: [<a href="mailto:info@wsb.sh" target="blank" >info@wsb.sh</a>].

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p><span style={{ color: '#ffffff' }}>
                    <span style={{ fontSize: '11pt' }}>
                        <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                            <strong style={{ fontWeight: 'bold' }}>
                                <span style={{ fontSize: '18.0pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        8. MISCELLANEOUS PROVISIONS

                                    </span>
                                </span>
                            </strong>
                        </span>
                    </span>
                </span>
                </p>
                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        8.1. Because of the uncertainty of future events and circumstance, we do not guarantee that its forecasts, projections, advice, recommendations or the contents of any report, presentation or other document will be achievable, and you acknowledge and agree that we gives the same to address specific circumstances at the time.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        8.2. We may assign, transfer, mortgage, charge, declare a trust of, or deal in any other manner with any or all of its rights and obligations under these Terms without your prior written consent. You shall not assign, transfer, mortgage, charge, declare a trust of, or deal in any other manner with any or all of your rights and obligations under these Terms without the our prior written consent.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        8.3. These Terms shall be governed in all respects, including as to validity, interpretation and effect, by the laws of the Cook Islands.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        8.4. If the User has a potential legal dispute, claim or cause of action against us, the User shall first (prior to initiating any litigation proceedings) contact us by sending an email to: [<a href="mailto:info@wsb.sh" target="blank" >info@wsb.sh</a>] (subject line: "Dispute") describing the nature of the potential dispute, claim or cause of action and providing all relevant documentation and evidence thereof. If so elected by us, user shall use commercially reasonable efforts to negotiate a settlement of any such legal dispute, claim or cause of action within 60 (sixty) calendar days of the delivery of such email. Any such dispute, claim or cause of action that is not finally resolved by a binding, written settlement agreement within such 60 (sixty) calendar days shall be brought and resolved exclusively in accordance with section 8.5 below.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        8.5. Except as set forth in section 8.4, all claims, disputes and controversies directly or indirectly arising out of or in connection with or directly or indirectly relating to the Terms or any of the matters or transactions contemplated by the Terms (for the avoidance of doubt, including any claim seeking to invalidate, or alleging that, all or any part of the Terms is unenforceable, void or voidable) shall be finally settled exclusively by the courts of the Cook Islands. YOU AND WE HEREBY IRREVOCABLY WAIVES ANY AND ALL RIGHT TO TRIAL BY JURY IN ANY ACTION, SUIT OR OTHER LEGAL PROCEEDING ARISING OUT OF OR RELATED TO THE TERMS OR THE TRANSACTIONS CONTEMPLATED HEREBY IN ANY JURISDICTION. ALL USERS HEREBY AGREE THAT ANY CLAIM WITH RESPECT TO ANY DISPUTE SHALL BE CONDUCTED IN THEIR INDIVIDUAL CAPACITIES ONLY AND NOT AS A CLASS ACTION OR OTHER REPRESENTATIVE ACTION, AND THE USERS EXPRESSLY WAIVE THEIR RIGHT TO FILE A CLASS ACTION OR SEEK RELIEF ON A CLASS BASIS IN ANY JURISDICTION. USERS SHALL BRING CLAIMS AGAINST US ONLY IN THEIR INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        8.6. These Terms set forth the entire agreement between you and us with regard to the subject matter hereof.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        8.7. Should any term, condition, provision or part of these Terms be found to be unlawful, invalid, illegal or unenforceable, that portion shall be deemed null and void and severed from these Terms for all purposes, but such illegality, or invalidity or unenforceability shall not affect the legality, validity or enforceability of the remaining parts of these Terms, and the remainder of these Terms shall remain in full force and effect, unless such would be manifestly inequitable or would serve to deprive either Party of a material part of what it bargained for in entering into these Terms.

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>

                <br />
                <p>
                    <span style={{ color: '#ffffff' }}>
                        <span style={{ fontSize: '11pt' }}>
                            <span style={{ fontFamily: 'Calibr ,sans-serif' }}>
                                <span style={{ fontSize: '13.5pt' }}>
                                    <span style={{ fontFamily: 'EB Garamond, serif' }}>
                                        *The User hereby acknowledges and agrees that the owner of the IP Rights to the Infrastructure is Web3 Foundation LLC, a legal entity incorporated under the laws of the Cook Islands, together with its Affiliates, successors and permitted assigns

                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </p>



                <br /><br /><br />


            </div>
            <div className="container">

                <div >
                    <div className="contacts">
                        <div className="contact-wrap">
                            <h3 className="contact__title">Email:</h3>
                            <ul className="contact__button-list">
                                <li className="contact__button-item">
                                    <a className="contact__button-link" href="mailto:info@wsb.sh">info@wsb.sh</a>
                                </li>
                            </ul>
                        </div>
                        <div className="contact-wrap">
                            <h3 className="contact__title">Telegram:</h3>
                            <ul className="contact__button-list">
                                <li className="contact__button-item">
                                    <a className="contact__button-link" href="https://t.me/wsbsh">@wsbsh</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
} 